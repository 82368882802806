import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, Route, RouteProps } from 'react-router-dom';
import { RootState } from '../Store';
import { decode } from 'jsonwebtoken';
import { clearAuth } from '../Store/authSlice'; // Import the clearAuth action
import LoggedInLayout from '../Layouts/LoggedInLayout';

const PrivateRoute: React.FC<RouteProps> = () => {
  const { isAuthenticated, token } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch(); // Create a dispatch function

  if (!isAuthenticated || !token) {
    return <Navigate to="/login" replace />;
  }

  try {
    const decoded: any = decode(token);

    if (decoded.exp * 1000 < Date.now()) {
      dispatch(clearAuth()); // Dispatch the clearAuth action when token has expired
      return <Navigate to="/login" replace />;
    }
  } catch (error) {
    dispatch(clearAuth()); // Dispatch the clearAuth action when token is invalid
    return <Navigate to="/login" replace />;
  }

  return <LoggedInLayout><Outlet /></LoggedInLayout>;
};

export default PrivateRoute;

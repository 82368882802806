import React from 'react'

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from 'socket.io-client';
import axios from "axios";

import { RootState } from "../Store";
import {
  HiMusicalNote,
  MdSkipPrevious,
  MdPlayArrow,
  MdSkipNext,
  RiVolumeUpFill,
  MdPause,
  RiVolumeMuteFill,
} from "../Config/icons";
import { setAd, setSong, setSongStatusToStopped, updateSongStatus } from "../Store/songSlice";

import styles from "../Styles/Footer.module.css";

function Footer() {
  const dispatch = useDispatch();
  const audioRef = useRef<any>();
  const [paused, setPaused] = useState(true);
  const [volume, setVolume] = useState(100);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  const ad: any = useSelector((state: RootState) => state.song.ad);
  const songCover: string | null = useSelector(
    (state: RootState) => state.song.cover
  );
  const artistName: string = useSelector(
    (state: RootState) => state.song.artistName
  );
  const { song, name: songName } = useSelector((state: RootState) => state.song);
  const songs: any = useSelector((state: RootState) => state.playlist.songs);
  const token: any = useSelector((state: RootState) => state.auth.token);

  const playSong = async (id: string) => {
    const { data } = await axios.get(`/songs/${id}`, {
      headers: {
        Authorization: token,
      },
    });

    const { ad, song } = data;

    dispatch(setAd({ ad }));
    dispatch(
      setSong({
        cover: song.cover,
        name: song.name,
        artistName: song.artistName,
        song: song.song,
      })
    );
  };

  useEffect(() => {
    playSong(songs[currentSongIndex]._id);
  }, [songs]);

  useEffect(() => {
    if (songName) {
      console.log(songName)
      updateSongStatus({
        name: songName
      });
    }
  }, [songName]);

  useEffect(() => {
    if (song) {
      setPaused(false);
    }

    if (ad) {
      audioRef.current.play();
      setPaused(false);
    }
  }, [ad, song, setPaused]);

  useEffect(() => {
    audioRef.current.volume = volume / 100;
  }, [volume]);

  const togglePlay = () => {
    if (audioRef.current.paused) {
      setPaused(false);
      audioRef.current.play();

      updateSongStatus({
        name: songName
      });
    } else {
      setPaused(true);
      audioRef.current.pause();

      setSongStatusToStopped();
    }
  };

  const handleTimeUpdate = (e: any) => {
    const target = e.target;
    setDuration(target.duration);
    setCurrentTime(target.currentTime);

    if (ad && target.currentTime >= target.duration) {
      dispatch(setAd({ ad: null }));
    } else {
      const songsNumber = songs.length;
      const songEnded = target.currentTime >= target.duration;

      if (songsNumber > 1 && songEnded) {
        if (currentSongIndex < (songsNumber - 1)) {
          setCurrentSongIndex(currentSongIndex + 1);
          playSong(songs[currentSongIndex + 1]._id);
          setCurrentTime(0);
          togglePlay();
        } else {
          setPaused(true);
          setSongStatusToStopped();
        }
      } else if (songsNumber == 1 && songEnded) {
        setPaused(true);
        setSongStatusToStopped();
      }
    }
  };

  const changeCurrentTime = (e: any) => {
    if (ad) {
      e.preventDefault();
      return;
    }

    const target = e.target;
    const value = target.value;
    audioRef.current.currentTime = value;
    setCurrentTime(value);
  };

  return (
    <>
      <audio
        onTimeUpdate={handleTimeUpdate}
        ref={audioRef}
        src={ad ? ad.audio : song}
        autoPlay
      ></audio>
      <footer className={styles.footer}>
        <div className={styles.musicInfo}>
          {songCover ? (
            <div className={styles.icon}>
              <img src={ad ? ad.cover : songCover} alt={songName ?? "song image"} />
            </div>
          ) : (
            <div className={styles.icon}>
              <HiMusicalNote />
            </div>
          )}
          <div className={styles.details}>
            <h4 className={styles.songName}>{ad ? ad.name : songName}</h4>
            <p className={styles.artistName}>{ad ? "AD" : artistName}</p>
          </div>
        </div>
        <div className={styles.controls}>
          <div className={styles.controlButtons}>
            <div className={styles.controlButton}>
              <MdSkipPrevious />
            </div>
            <div onClick={togglePlay} className={styles.controlButton}>
              {paused ? <MdPlayArrow /> : <MdPause />}
            </div>
            <div className={styles.controlButton}>
              <MdSkipNext />
            </div>
          </div>
          <div className={styles.progressBar}>
            <input
              onChange={changeCurrentTime}
              type="range"
              value={currentTime}
              max={duration}
            />
          </div>
        </div>
        <div className={styles.volumeControls}>
          <span className={styles.volumeIcon}>
            {volume > 0 ? <RiVolumeUpFill /> : <RiVolumeMuteFill />}
          </span>
          <input
            type="range"
            min="0"
            max="100"
            value={volume}
            className="volume-range"
            onChange={(e) => setVolume(+e.target.value)}
          />
        </div>
      </footer>
    </>
  );
}

export default Footer;
